import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import './Home.css'; // Import the CSS file for styling

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <div className="home-image">
        {/* Added lazy loading and a set aspect ratio for the image */}
        <img 
          src="https://media.githubusercontent.com/media/balloon4computing/artifact/main/balloon.jpg" 
          alt="SFU SAT team" 
          loading="lazy"
          width="600" /* Specify a width */
          height="400" /* Specify a height */
        />
      </div>
      <div className="home-content">
        <h1>Making Space Accessible.</h1>
        <p>
          Here at SFU SAT, we’re building more than just a satellite. Join us today to develop your skills, 
          prepare for a future career, and build lasting personal and professional connections within our 
          extensive student and alumni network.
        </p>
        <Link to="/contact" className="home-button">Join Us</Link>
      </div>

      {/* ALEASAT Section */}
      {/* <div className="aleasat-container">
        <div className="aleasat-image">
          <img 
            src="/images/aleasat.png" 
            alt="ALEASAT Cubesat" 
            loading="lazy"
          />
        </div>
        <div className="aleasat-content">
          <h2>ALEASAT</h2>
          <p>
            Our flagship Cubesat project, built in collaboration with the European Space Agency’s Fly Your Satellite program.
          </p>
          <Link to="/projects" className="home-button">Learn More</Link>
        </div>
      </div> */}

      {/* Sponsors Section */}
      {/* <div className="sponsors-container">
        <div className="sponsors-image">
          <img 
            src="/images/sponsors.png" 
            alt="Our Sponsors" 
            loading="lazy"
          />
        </div>
        <div className="sponsors-content">
          <h2>Our Sponsors</h2>
          <p>Help us continue to educate and inspire future generations.</p>
          <Link to="/sponsor" className="home-button">Sponsor Us</Link>
        </div>
      </div>
       */}
    </div>
    
  );
};

export default Home;
